import React, { useEffect, useState } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation }  from 'react-router-dom';
import axios from 'axios';



let deferredPrompt;  
    
var QRCode = require('qrcode.react');
const ApiUrl = 'http://148.72.206.209:1337';




function App() {
  const [installable, setInstallable] = useState(false);
  const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
  const repo = window.location.href;
  const logo ={};

  const [apiData, setApiData] = useState({});

  const shareLink = async () => {
    try {
      await navigator.share({ title: `${apiData.name}`, url: window.location.href });
      console.log("Data was shared successfully");
      console.log(repo,apiData.name );
    } catch (err) {
      console.error("Share failed:", err.message);
    }
  };


  useEffect(() => {
    

     const urlValue=getQueryParams();
     console.log(urlValue.cid);
     axios.get(`https://60fd579a1fa9e90017c70df9.mockapi.io/vcards/${urlValue.cid}`).
     then(res =>{
    setApiData(res.data)
      //const logo = res.data.logo ;
      console.log("repo",repo);
    })

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);

  const handleInstallClick = (e) => {
      // Hide the app provided install promotion
      setInstallable(false);
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
  };
  
  return (



    <div className="App">
    
    <div style={{float:"right",margin:"10px"}} >
          {installable &&
            <Button variant="outline-primary" size="sm" onClick={handleInstallClick}>
              Add to Home Screen
            </Button>
            
          }
        </div>

<div className="outer-wrap">
        <div className="container">
            <div className="col-12">
                <div className="row">
                    <div className="col-lg-6 no-padding col-center">
                        <div className="card bg-white custom-card">
{console.log("Benny",apiData.description)}
                            {apiData.logo &&  <img src={apiData.logo} className="logoSize" />} 

                            {apiData.description && <p className="introPara">{apiData.description}</p>}

                            <p className="QrHolder">
                            <QRCode
                              value={repo}
                              size={180}
                              bgColor={"#ffffff"}
                              fgColor={"#ba1b19"}
                              level={"L"}
                              includeMargin={false}
                              renderAs={"svg"} imageSettings={{
                                src: "/assets/img/favicon-32x32.png",
                                height: 20,
                                width: 20,
                                excavate: true,
                              }}
                            />
                            </p>
                            <p className="introPara"></p>
                            <p >
                            <Button variant="success" onClick={shareLink}>Share Link</Button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    
 
    </div>

    

  );
}

export default App;
